import { useEffect, useState } from "react";

function AvisGoogle() {
    const [lang, setLang] = useState(localStorage.getItem("LangParameter"));

    useEffect(() => {
        setLang(localStorage.getItem("LangParameter"));
    }, []);
    
    return (
        <section id="Review" className="tarif-gite">
            <div className="headTarif">
                <h2>{lang === "FR" ? "Avis clients" : "Customer reviews"}</h2>
            </div>
            <div className="group-avis">
                <div className="google-avis">     
                    <h3>Google Avis</h3>
                    <div className="content-avis">
                        <iframe src="https://widgets.sociablekit.com/google-reviews/iframe/25516742" className="iframe-review" width="100%" height="450px"></iframe>    
                    </div>
                </div>
                <div className="airbnb-avis">    
                    <h3>Airbnb Avis</h3>
                    <div className="content-avis">
                        <iframe src='https://widgets.sociablekit.com/airbnb-reviews/iframe/25516747' className="iframe-review" width='100%' height='450px'></iframe>
                    </div>
                </div>
            </div>
        
        </section>
    );
}

export default AvisGoogle;