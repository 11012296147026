import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar } from '@fortawesome/free-solid-svg-icons'
import videoPresentation from "../main/gitevideo1920hd.mp4";
function Main() {
  let verif = localStorage.getItem("LangParameter");
  if (verif === "FR"){
    return (
      <>
        <div id='Home' className="container_main">
          <div className='entete'>
            <h1 >Au Gîte Du Bois</h1>
            <div className='content-star'>
              <FontAwesomeIcon className='star_ico' icon={faStar} />
              <FontAwesomeIcon className='star_ico' icon={faStar} />
              <FontAwesomeIcon className='star_ico' icon={faStar} />
              <FontAwesomeIcon className='star_ico' icon={faStar} />
            </div>
            <p className='presentation-main'>
              Réservez vos vacances dans un gîte quatre étoiles 
              au coeur de nombreuses destinations 
              touristiques. Un lieu rempli de charme, 
              calme et reposant. Entre mer et verdure, 
              les amateurs d'escapades bucoliques, seront 
              sensibles au style moderne et à l'ambiance conviviale 
              revendiquée.
            </p>
          </div>
          <div className='contain-video'>
            <video className='video-present' type="video/mp4"  controls="controls" src={videoPresentation}/>
          </div>
        </div>
      </>
    )
  }else{
    return (
      <>
        <div id='Home' className="container_main">
          <div className='entete'>
            <h1 >Au Gîte Du Bois</h1>
            <div className='content-star'>
              <FontAwesomeIcon className='star_ico' icon={faStar} />
              <FontAwesomeIcon className='star_ico' icon={faStar} />
              <FontAwesomeIcon className='star_ico' icon={faStar} />
              <FontAwesomeIcon className='star_ico' icon={faStar} />
            </div>
            <p className='presentation-main'>
              Book your holiday in a four-star lodge
              in the heart of many tourist
              destinations. A place full of charm,
              calm and relaxing. Between sea and greenery,
              lovers of bucolic getaways will be
              sensitive to the modern style and the friendly atmosphere
              claimed.
            </p>
          </div>
          <div className='contain-video'>
            <video className='video-present' type="video/mp4"  controls="controls" src={videoPresentation}/>
          </div>
        </div>
      </>
    )
  }
};

export default Main;