import React, { Component } from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

import imageGite1 from "../img/gite1.webp"
import imageGite2 from "../img/gite2.webp"
import imageGite3 from "../img/gite3.webp"
import imageGite4 from "../img/gite4.webp"
import imageGite5 from "../img/gite5.webp"
import imageGite6 from "../img/gite6.webp"

import imagePMR1 from '../img/pmr1.webp'
import imagePMR2 from '../img/pmr2.webp'
import imagePMR3 from '../img/pmr3.webp'
import imagePMR4 from '../img/pmr4.webp'
import imagePMR5 from '../img/pmr5.webp'

import imageSP1 from '../img/suiteparentale.webp'
import imageSP2 from '../img/suiteparentale1.webp'
import imageSP3 from '../img/suiteparentale2.webp'
import imageSP4 from '../img/suiteparentale3.webp'
import imageSP5 from '../img/suiteparentale4.webp'

import imageCRP1 from '../img/chambrerose1.webp'
import imageCRP2 from '../img/chambrerose2.webp'
import imageCRP3 from '../img/chambrerose3.webp'
import imageCRP4 from '../img/chambrerose4.webp'
import imageCRP5 from '../img/chambrerose5.webp'

import imageCM1 from '../img/chambremoka1.webp'
import imageCM2 from '../img/chambremoka2.webp'
import imageCM3 from '../img/chambremoka3.webp'
import imageCM4 from '../img/chambremoka4.webp'
import imageCM5 from '../img/chambremoka5.webp'

import imageDortoir1 from '../img/dortoir1.webp'
import imageDortoir2 from '../img/dortoir2.webp'


function GiteEtChambre() {

    let verif = localStorage.getItem("LangParameter");
    if (verif === "FR"){
        return (
            <>
        {/* partie gite version fr */}
                <div className='contain-present-gite' >
                    <h2>Le Gîte</h2>
                    <p>
                        Un cocon de charme à Avranches, sur la côte normande.<br/>
                        Situé à seulement 30 minutes du Mont Saint-Michel, pour des vacances inoubliables !
                    </p>
                    <Carousel>
                        <div>
                            <img src={imageGite1} />
                        </div>
                        <div>
                            <img src={imageGite2} />
                        </div>
                        <div>
                            <img src={imageGite3} />
                        </div>
                        <div>
                            <img src={imageGite4} />
                        </div>
                        <div>
                            <img src={imageGite5} />
                        </div>
                        <div>
                            <img src={imageGite6} />
                        </div>
                    </Carousel>
                    <div className='contain-list_option'>
                        <h3>Le Gîte</h3>
                        <p>Un grand séjour, une cuisine, une salle à manger et un salon cosy de 63 m2.</p>
                        <p>Quatre chambres (dont une suite parentale et une chambre PMR).</p>
                        <p>Un dortoir (capacité de six personnes).</p>
                        <h3>Le jardin aménagé</h3>
                        <p>Vous retrouverez pour vos loisirs plusieurs équipements de plein air(terrain de pétanque, jeu de molki, tennis de table, badminton et hamac).</p>
                        <p>Ces équipements sont à votre disposition pour votre séjour.</p>
                    </div>
                </div>
        {/* partie PMR */}
                <div className='contain-present-gite contain-present-room' id='Room'>
                    <h2>Chambre PMR</h2>
                    <p>
                        Accessible aux personnes à mobilité réduite.
                        Optimisé pour garantir accessibilité, autonomie maximale, confort et sécurité.
                    </p>
                    <Carousel>
                        <div>
                            <img src={imagePMR1} />
                        </div>
                        <div>
                            <img src={imagePMR2} />
                        </div>
                        <div>
                            <img src={imagePMR3} />
                        </div>
                        <div>
                            <img src={imagePMR4} />
                        </div>
                        <div>
                            <img src={imagePMR5} />
                        </div>
                    </Carousel>
                    <div className='contain-list_option'>
                        <h3>Située au rez-de-chaussée</h3>
                        <p>Un lit double.</p>
                        <p>Salle de bain avec douche, et toilettes.</p>
                        <p>Équipements en chambre : chauffage indépendant, penderie, bureau.</p>
                    </div>
                </div>
        {/* partie suite parentale */}
                <div className='contain-present-gite contain-present-room'>
                    <h2>Suite Parentale</h2>
                    <Carousel>
                        <div>
                            <img src={imageSP5} />
                        </div>
                        <div>
                            <img src={imageSP2} />
                        </div>
                        <div>
                            <img src={imageSP3} />
                        </div>
                        <div>
                            <img src={imageSP4} />
                        </div>
                        <div>
                            <img src={imageSP1} />
                        </div>
                    </Carousel>
                    <div className='contain-list_option'>
                        <h3>Située au premier étage</h3>
                        <p>Un lit double.</p>
                        <p>Salle de bain avec douche, beignoire et toilettes.</p>
                        <p>Équipements en chambre : chauffage indépendant, penderie.</p>
                    </div>
                </div>
        {/* partie chambre rose poudre */}
                <div className='contain-present-gite contain-present-room'>
                    <h2>Chambre Rose Poudré</h2>
                    <Carousel>
                        <div>
                            <img src={imageCRP1} />
                        </div>
                        <div>
                            <img src={imageCRP2} />
                        </div>
                        <div>
                            <img src={imageCRP3} />
                        </div>
                        <div>
                            <img src={imageCRP4} />
                        </div>
                        <div>
                            <img src={imageCRP5} />
                        </div>
                    </Carousel>
                    <div className='contain-list_option'>
                        <h3>Située au premier étage</h3>
                        <p>Un lit double.</p>
                        <p>Équipements en suite : chauffage indépendant, penderie.</p>
                        <p>Commun à l’étage : accès salle de bain avec douche, toilettes, Sauna 4 personnes.</p>
                    </div>
                </div>
     {/* partie chambre Moka */}
                <div className='contain-present-gite contain-present-room'>
                    <h2>Chambre Moka</h2>
                    <Carousel>
                        <div>
                            <img src={imageCM1} />
                        </div>
                        <div>
                            <img src={imageCM2} />
                        </div>
                        <div>
                            <img src={imageCM3} />
                        </div>
                        <div>
                            <img src={imageCM4} />
                        </div>
                        <div>
                            <img src={imageCM5} />
                        </div>
                    </Carousel>
                    <div className='contain-list_option'>
                        <h3>Située au premier étage</h3>
                        <p>Un lit double.</p>
                        <p>Équipements en suite : chauffage indépendant, penderie.</p>
                        <p>Commun à l’étage : accès salle de bain avec douche, toilettes, Sauna 4 personnes.</p>
                    </div>
                </div>
        {/* partie dortoir */}
                <div className='contain-present-gite contain-present-room'>
                    <h2>Dortoir</h2>
                    <Carousel>
                        <div>
                            <img src={imageDortoir1} />
                        </div>
                        <div>
                            <img src={imageDortoir2} />
                        </div>
                    </Carousel>
                    <div className='contain-list_option'>
                        <h3>Situé au deuxième étage</h3>
                        <p>Quatre lits individuels.</p>
                        <p>Un canapé convertible (2 places).</p>
                        <p>Équipements : chauffage indépendant, penderie, toilettes.</p>
                    </div>
                </div>
            </>
        );

    }else{

        return (
            <>
        {/* partie gite version EN */}
                <div className='contain-present-gite'>
                    <h2>Have an unforgettable holiday!</h2>
                    <p>
                        A charming cocoon in Avranches, on the Normandy coast.
                        Located just 30 minutes from Mont Saint-Michel!
                    </p>
                    <Carousel>
                        <div>
                            <img src={imageGite1} />
                        </div>
                        <div>
                            <img src={imageGite2} />
                        </div>
                        <div>
                            <img src={imageGite3} />
                        </div>
                        <div>
                            <img src={imageGite4} />
                        </div>
                        <div>
                            <img src={imageGite5} />
                        </div>
                        <div>
                            <img src={imageGite6} />
                        </div>
                    </Carousel>
                    <div className='contain-list_option'>
                        <h3>The Gîte</h3>
                        <p>A large living room, a kitchen, a dining room and a cozy lounge of 63 m2.</p>
                        <p>Four bedrooms (including a master suite and a PMR bedroom).</p>
                        <p>A dormitory (capacity of six people).</p>
                        <h3>The landscaped garden</h3>
                        <p>For your leisure time, you will find several outdoor facilities (petanque ground, molki game, table tennis, badminton and hammock).</p>
                        <p>These facilities are at your disposal for your stay.</p>
                    </div>
                </div>
        {/* partie PMR */}
                <div className='contain-present-gite contain-present-room' id='Room'>
                    <h2>Handicap-accessible room</h2>
                    <p>
                        Accessible to people with reduced mobility.
                        Optimized to ensure accessibility, maximum autonomy, comfort and safety.
                    </p>
                    <Carousel>
                        <div>
                            <img src={imagePMR1} />
                        </div>
                        <div>
                            <img src={imagePMR2} />
                        </div>
                        <div>
                            <img src={imagePMR3} />
                        </div>
                        <div>
                            <img src={imagePMR4} />
                        </div>
                        <div>
                            <img src={imagePMR5} />
                        </div>
                    </Carousel>
                    <div className='contain-list_option'>
                        <h3>Located on the ground floor</h3>
                        <p>A double bed.</p>
                        <p>Bathroom with shower and toilet.</p>
                        <p>Room facilities: independent heating, wardrobe, desk.</p>
                    </div>
                </div>
        {/* partie suite parentale */}
                <div className='contain-present-gite contain-present-room'>
                    <h2>Master Suite</h2>
                    <Carousel>
                        <div>
                            <img src={imageSP5} />
                        </div>
                        <div>
                            <img src={imageSP2} />
                        </div>
                        <div>
                            <img src={imageSP3} />
                        </div>
                        <div>
                            <img src={imageSP4} />
                        </div>
                        <div>
                            <img src={imageSP1} />
                        </div>
                    </Carousel>
                    <div className='contain-list_option'>
                        <h3>Located on the first floor</h3>
                        <p>A double bed.</p>
                        <p>Bathroom with shower, bathtub and toilet.</p>
                        <p>Room facilities: independent heating, wardrobe.</p>
                    </div>
                </div>
        {/* partie chambre rose poudre */}
                <div className='contain-present-gite contain-present-room'>
                    <h2>Powder Pink Room</h2>
                    <Carousel>
                        <div>
                            <img src={imageCRP1} />
                        </div>
                        <div>
                            <img src={imageCRP2} />
                        </div>
                        <div>
                            <img src={imageCRP3} />
                        </div>
                        <div>
                            <img src={imageCRP4} />
                        </div>
                        <div>
                            <img src={imageCRP5} />
                        </div>
                    </Carousel>
                    <div className='contain-list_option'>
                        <h3>Located on the first floor</h3>
                        <p>A double bed.</p>
                        <p>En-suite facilities: independent heating, wardrobe.</p>
                        <p>Shared upstairs: access to bathroom with shower, toilet, 4-person sauna.</p>
                    </div>
                </div>
     {/* partie chambre Moka */}
                <div className='contain-present-gite contain-present-room'>
                    <h2>Moka Room</h2>
                    <Carousel>
                        <div>
                            <img src={imageCM1} />
                        </div>
                        <div>
                            <img src={imageCM2} />
                        </div>
                        <div>
                            <img src={imageCM3} />
                        </div>
                        <div>
                            <img src={imageCM4} />
                        </div>
                        <div>
                            <img src={imageCM5} />
                        </div>
                    </Carousel>
                    <div className='contain-list_option'>
                        <h3>Located on the first floor</h3>
                        <p>A double bed.</p>
                        <p>En-suite facilities: independent heating, wardrobe.</p>
                        <p>Shared upstairs: access to bathroom with shower, toilet, 4-person sauna.</p>
                    </div>
                </div>
        {/* partie dortoir */}
                <div className='contain-present-gite contain-present-room'>
                    <h2>Dormitory</h2>
                    <Carousel>
                        <div>
                            <img src={imageDortoir1} />
                        </div>
                        <div>
                            <img src={imageDortoir2} />
                        </div>
                    </Carousel>
                    <div className='contain-list_option'>
                        <h3>Located on the second floor</h3>
                        <p>Four single beds.</p>
                        <p>A convertible sofa (2 places).</p>
                        <p>Facilities: independent heating, wardrobe, toilets.</p>
                    </div>
                </div>
            </>
        );

    }

};

export default GiteEtChambre;
