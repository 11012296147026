
import logo from "../img/agdb.png"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar, faPhone, faMapLocation, faHouse, faBed, faEuro, faHeart,faHandshakeSimple } from '@fortawesome/free-solid-svg-icons'
import { faFacebookSquare, faInstagramSquare } from "@fortawesome/free-brands-svg-icons";
function Header() {
  let verif = localStorage.getItem("LangParameter");
  function ChangeLanguage(params) {

    verif === "FR" && params.target.value === "EN" ? localStorage.setItem("LangParameter", "EN") : localStorage.setItem("LangParameter", "FR");
    verif === "EN" && params.target.value === "FR" ? localStorage.setItem("LangParameter", "FR") : localStorage.setItem("LangParameter", "EN");
    window.location.reload()
  }
  function closeMenu() {
    let elementMenu = document.getElementsByClassName('checkbox')
    elementMenu = [...elementMenu]
    elementMenu[0].checked = false
  }
  if (verif === "FR") {
    return (
      <>
        <nav>
          <div className="navbar">
            <div className="container nav-container">
              <input className="checkbox" type="checkbox" name="" id="" />
              <div className="hamburger-lines">
                <span className="line line1"></span>
                <span className="line line2"></span>
                <span className="line line3"></span>
              </div>
              <div className="logo">
                <img className="logoimg" src={logo} alt="Logo au gite du bois" />;
              </div>
              <div className="menu-items">
                <div className="nav-group1">
                  <img className="logo-transparent" src={logo} />
                  <div className='entete'>
                    <h1 >Au Gîte Du Bois</h1>
                    <div className='content-star'>
                      <FontAwesomeIcon className='star_ico' icon={faStar} />
                      <FontAwesomeIcon className='star_ico' icon={faStar} />
                      <FontAwesomeIcon className='star_ico' icon={faStar} />
                      <FontAwesomeIcon className='star_ico' icon={faStar} />
                    </div>
                  </div>
                  <div className="nav-g">
                    <li onClick={closeMenu}><a className="select-btn" href="#Home"><FontAwesomeIcon className='star_ico-btn' icon={faHouse} /><span className="name-select-btn">Accueil</span></a></li>
                    <li onClick={closeMenu}><a className="select-btn" href="#Room"><FontAwesomeIcon className='star_ico-btn' icon={faBed} /><span className="name-select-btn">Chambres</span></a></li>
                    <li onClick={closeMenu}><a className="select-btn" href="#Price"><FontAwesomeIcon className='star_ico-btn' icon={faEuro} /><span className="name-select-btn">Tarifs</span></a></li>
                    <li onClick={closeMenu}><a className="select-btn" href="#Review"><FontAwesomeIcon className='star_ico-btn' icon={faHeart} /><span className="name-select-btn">Avis</span></a></li>
                    <li onClick={closeMenu}><a className="select-btn" href="#Booking"><FontAwesomeIcon className='star_ico-btn' icon={faHandshakeSimple} /><span className="name-select-btn">Reservation</span></a></li>
                  </div>
                </div>
                <div className="nav-group2">
                  <div className="select-language">
                    <h2 className="info_lang">Choix de la langue</h2>
                    <div className="contain-btn">
                      <div className="btn-language">
                        <button onClick={null} className="button-lang btn-active" value="FR">Français</button>
                      </div>
                      <div className="btn-language">
                        <button onClick={ChangeLanguage} className="button-lang" value="EN">English</button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="nav-group3">
                  <div className="select-language">
                    <h2 className="info_lang">Contact et itinéraire</h2>
                    <div className='content-groupe-nav_2'>
                      <li>
                        <a className="select-btn_ico" href="tel:+33601028535"><FontAwesomeIcon className='ico' icon={faPhone} /><span className="name-select-btn">Téléphoner</span></a>
                      </li>
                      <li>
                        <a className="select-btn_ico" href="https://www.facebook.com/Au-Gite-Du-Bois-2069159439861829"><FontAwesomeIcon icon={faFacebookSquare} className="icoSocial" /><span className="name-select-btn">Facebook</span></a>
                      </li>
                      <li>
                        <a className="select-btn_ico" href='https://www.instagram.com/augitedubois/?hl=fr'><FontAwesomeIcon icon={faInstagramSquare} className="icoSocial" /><span className="name-select-btn">Instagram</span></a>
                      </li>
                      <li>
                        <a className="select-btn_ico" href="https://www.google.com/maps/dir/49.0834471,-1.4483277/4+Le+Petit+Andillou,+50300+Ponts/@48.8945809,-1.6149684,10z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x480955860af92997:0x720635645a3c804e!2m2!1d-1.3269999!2d48.7064013"><FontAwesomeIcon icon={faMapLocation} className="ico" /><span className="name-select-btn">Itinéraire</span></a>
                      </li>
                    </div>
                  </div>
                </div>
                <p className="licence">©2025 Au Gîte Du Bois</p>
              </div>
            </div>
          </div>
        </nav>
      </>
    )
  } else {
    return (
      <>
        <nav>
          <div className="navbar">
            <div className="container nav-container">
              <input className="checkbox" type="checkbox" name="" id="" />
              <div className="hamburger-lines">
                <span className="line line1"></span>
                <span className="line line2"></span>
                <span className="line line3"></span>
              </div>
              <div className="logo">
                <img className="logoimg" src={logo} alt="Logo au gite du bois" />;
              </div>
              <div className="menu-items">
                <div className="nav-group1">
                  <img className="logo-transparent" src={logo} />
                  <div className='entete'>
                    <h1 >Au Gîte Du Bois</h1>
                    <div className='content-star'>
                      <FontAwesomeIcon className='star_ico' icon={faStar} />
                      <FontAwesomeIcon className='star_ico' icon={faStar} />
                      <FontAwesomeIcon className='star_ico' icon={faStar} />
                      <FontAwesomeIcon className='star_ico' icon={faStar} />
                    </div>
                  </div>
                  <div className="nav-g">
                    <li onClick={closeMenu}><a className="select-btn" href="#Home"><span className="name-select-btn">Home</span></a></li>
                    <li onClick={closeMenu}><a className="select-btn" href="#Room"><span className="name-select-btn">Rooms</span></a></li>
                    <li onClick={closeMenu}><a className="select-btn" href="#Price"><span className="name-select-btn">Price</span></a></li>
                    <li onClick={closeMenu}><a className="select-btn" href="#Review"><span className="name-select-btn">Review</span></a></li>
                    <li onClick={closeMenu}><a className="select-btn" href="#Booking"><span className="name-select-btn">Booking</span></a></li>
                  </div>
                </div>
                <div className="nav-group2">
                  <div className="select-language">
                    <h2 className="info_lang">Language selection</h2>
                    <div className="contain-btn">
                      <div className="btn-language">
                        <button onClick={ChangeLanguage} className="button-lang" value="FR">French</button>
                      </div>
                      <div className="btn-language">
                        <button onClick={null} className="button-lang btn-active" value="EN">English</button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="nav-group3">
                  <div className="select-language">
                    <h2 className="info_lang">Contact et Itinerary</h2>
                    <div className='content-groupe-nav_2'>
                      <li>
                        <a className="select-btn_ico" href="tel:+33601028535"><FontAwesomeIcon className='ico' icon={faPhone} /><span>Call the lodge</span></a>
                      </li>
                      <li>
                        <a className="select-btn_ico" href="https://www.facebook.com/Au-Gite-Du-Bois-2069159439861829"><FontAwesomeIcon icon={faFacebookSquare} className="icoSocial" /><span>Facebook</span></a>
                      </li>
                      <li>
                        <a className="select-btn_ico" href='https://www.instagram.com/augitedubois/?hl=fr'><FontAwesomeIcon icon={faInstagramSquare} className="icoSocial" /><span>Instagram</span></a>
                      </li>
                      <li>
                        <a className="select-btn_ico" href="https://www.google.com/maps/dir/49.0834471,-1.4483277/4+Le+Petit+Andillou,+50300+Ponts/@48.8945809,-1.6149684,10z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x480955860af92997:0x720635645a3c804e!2m2!1d-1.3269999!2d48.7064013"><FontAwesomeIcon icon={faMapLocation} className="ico" /><span>Itinerary</span></a>
                      </li>
                    </div>
                  </div>
                </div>
                <p className="licence">©2025 Au Gîte Du Bois</p>
              </div>
            </div>
          </div>
        </nav>
      </>
    )
  }
};

export default Header;