import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar, faFaceSmileBeam, faFaceRollingEyes, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons'
import axios from 'axios';

const urlProd = 'https://www.augitedubois.com/api/contact';
const urlDev = 'http://127.0.0.1:3000/api/contact'
const utilUrl = urlProd

function formBooking() {
    function changeNbPeople(e) {
        let qtMax = 14;
        let displayNbPeople = document.getElementsByClassName('value-nb-people')
        let getNbChild = document.getElementById('nbchild')
        let displayNbChild = document.getElementsByClassName('value-nb-child')
        displayNbPeople[0].innerHTML = e.target.value;
        getNbChild.max = qtMax - Number(e.target.value)
        if (Number(displayNbChild[0].innerHTML) > Number(getNbChild.value)) {
            displayNbChild[0].innerHTML = qtMax - Number(e.target.value)
        }
    }
    function changeNbChild(e) {
        let displayNbChild = document.getElementsByClassName('value-nb-child')
        displayNbChild[0].value = e.target.value
        displayNbChild[0].innerHTML = e.target.value
    }
    function activeBtnPage2(e) {
        let btnReady = document.getElementById('btn-next-two')
        let changeOption = document.getElementById('option-booking')
        btnReady.classList.add('BTNREADY')
        if (e.target.value == "full") {
            changeOption.value = "null"
            btnReady.href = "#form-info-three"
        } else {
            changeOption.value = "chambre pmr"
            btnReady.href = "#form-info-autre"
        }
    }
    function fomrPage1(e) {
        let name = document.getElementById('name')
        let forename = document.getElementById('forename')
        let email = document.getElementById('email')

        let alertErrorName = document.getElementById('alert-input-name')
        let alertErrorForename = document.getElementById('alert-input-forename')
        let alertErrorMail = document.getElementById('alert-input-mail')

        const nameRegex = /^(?=.{1,14}$)([a-zA-Z-]+[a-z]{2,7})$/
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
        let verifEmail = emailRegex.test(email.value)
        let verifName = nameRegex.test(name.value)
        let verifForname = nameRegex.test(forename.value)

        if (e.target.id == 'name') {
            verifName ? alertErrorName.innerHTML = "" : alertErrorName.innerHTML = "Non conforme";
        }
        if (e.target.id == 'forename') {
            verifForname ? alertErrorForename.innerHTML = "" : alertErrorForename.innerHTML = "Non conforme";
        }
        if (e.target.id == 'email') {
            verifEmail ? alertErrorMail.innerHTML = "" : alertErrorMail.innerHTML = "Non conforme";
        }
        if (verifEmail && verifForname && verifName) {
            let btnReady = document.getElementById('btn-next-one')
            btnReady.classList.add('BTNREADY')
            btnReady.href = "#form-info-two"

        } else {
            let btnReady = document.getElementById('btn-next-one')
            btnReady.classList.remove('BTNREADY')
            btnReady.href = ""
        }
    }
    function formPage4() {
        const dateStart = document.getElementById('dateStart')
        const dateEnd = document.getElementById('dateEnd')
        console.log(dateStart.value.length)

        if ((dateStart.value.length > 0) && (dateEnd.value.length > 0)) {
            let btnReady = document.getElementById('btn-next-four')
            btnReady.classList.add('BTNREADY')
            btnReady.href = "#form-info-five"
        } else {
            let btnReady = document.getElementById('btn-next-four')
            btnReady.classList.remove('BTNREADY')
            btnReady.href = "#form-info-four"
        }
    }

    function formPage5(e) {
        const tel = document.getElementById('phone')
        const address = document.getElementById('street-address')
        const codePostal = document.getElementById('postal-code')
        const city = document.getElementById('city')
        const regTel = /^(\+?\d{1,3}[-.\s]?)?(\(?\d{2,3}\)?[-.\s]?)?\d{3}[-.\s]?\d{4}$/
        const regAddress = /^\d+\s[A-Za-zÀ-ÿ0-9\s,.'-]+(?:\s[A-Za-zÀ-ÿ0-9\s,.'-]+)*(\s*,\s*[A-Za-zÀ-ÿ\s]+(?:\s[A-Za-zÀ-ÿ\s]+)*,\s*\d{5}(-\d{4})?)?$/
        const regPostalCode = /^[A-Za-z0-9]{3,10}(-[A-Za-z0-9]{3,10})?$/
        const regCity = /^[A-Za-zÀ-ÿ\s\-']{2,50}$/
        regTel.test(tel.value) ? tel.classList.add('inputvalid') : tel.classList.remove('inputvalid');
        regAddress.test(address.value) ? address.classList.add('inputvalid') : address.classList.remove('inputvalid');
        regPostalCode.test(codePostal.value) ? codePostal.classList.add('inputvalid') : codePostal.classList.remove('inputvalid');
        regCity.test(city.value) ? city.classList.add('inputvalid') : city.classList.remove('inputvalid');
        if (regTel.test(tel.value) && regAddress.test(address.value) && regPostalCode.test(codePostal.value) && regCity.test(city.value)) {
            let btnReady = document.getElementById('btn-next-five')
            btnReady.classList.add('BTNREADY')
            btnReady.href = "#form-info-six"
        } else {
            let btnReady = document.getElementById('btn-next-five')
            btnReady.classList.remove('BTNREADY')
            btnReady.href = "#form-info-five"
        }
    }

    function getDatePlus7Days() {
        const today = new Date();
        today.setDate(today.getDate() + 7);
        const year = today.getFullYear();
        const month = String(today.getMonth() + 1).padStart(2, '0');
        const day = String(today.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }
    function minDateEnd(e) {
        const dateEnd = document.getElementById('dateEnd')
        let date = new Date(e.target.value)
        date.setDate(date.getDate() + 2);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0')
        dateEnd.min = `${year}-${month}-${day}`
        formPage4()
    }

    function sendForm() {
        // Récupération des données du formulaire
        let nationality = document.getElementById('nationality').value;
        let name = document.getElementById('name').value;
        let forename = document.getElementById('forename').value;
        let email = document.getElementById('email').value;
        let tel = document.getElementById('phone').value;
        let address = document.getElementById('street-address').value;
        let codePostal = document.getElementById('postal-code').value;
        let city = document.getElementById('city').value;
        let modeleReservation = document.getElementsByClassName('card-choice')[0].value;
        let optionReservation = document.getElementById('option-booking').value;
        let dateStart = document.getElementById('dateStart').value;
        let dateEnd = document.getElementById('dateEnd').value;
        let nbPeople = document.getElementById('nbpeople').value;
        let nbChild = document.getElementById('nbchild').value;
        let message = document.getElementById('message-form').value;

        // Création d'un objet avec les données
        let clientInfo = {
            'nationality': nationality,
            'name': name,
            'forename': forename,
            'email': email,
            'tel': tel,
            'address': address,
            'codePostal': codePostal,
            'city': city,
            'modeleReservation': modeleReservation,
            'optionReservation': optionReservation,
            'dateStart': dateStart,
            'dateEnd': dateEnd,
            'nbPeople': nbPeople,
            'nbChild': nbChild,
            'message': message
        };
        axios.post(`${utilUrl}`, clientInfo)
            .then(response => {
                const redirect = `${window.location.origin}${window.location.pathname}#form-info-valid`;
                window.location.href = redirect;
            })
            .catch(error => {
                const redirect = `${window.location.origin}${window.location.pathname}#form-info-novalid`;
                window.location.href = redirect;
            });
    }
    function resetInfo() {
        window.location.reload()
    }
    let getDatePlus7D = getDatePlus7Days()






    let verif = localStorage.getItem("LangParameter");





    return (
        <>
            <section className='section-form' id='Booking'>
                <div className="content-form">
                    <div className='content-page-form'>
                        <h2 className="subtitle-form">{verif === "FR" ? "Réservation" : "Reservation"}</h2>
                        <div className='content-star'>
                            <FontAwesomeIcon className='star_ico' icon={faStar} />
                            <FontAwesomeIcon className='star_ico' icon={faStar} />
                            <FontAwesomeIcon className='star_ico' icon={faStar} />
                            <FontAwesomeIcon className='star_ico' icon={faStar} />
                        </div>
                        {/* formulaire */}
                        <form className='content-form-info'>
                            {/* premiere page */}
                            <div id="form-info-one" className="form-info">
                                <div className="group-info-name-client">
                                    <div className="content-input">
                                        <input onChange={fomrPage1} placeholder={verif === "FR" ? "Nom" : "Last Name"} type="text" name="name" id="name" />
                                        <span id="alert-input-name" className="alert-input"></span>
                                    </div>
                                    <div className="content-input">
                                        <input onChange={fomrPage1} placeholder={verif === "FR" ? "Prénom" : "First Name"} type="text" name="prenom" id="forename" />
                                        <span id="alert-input-forename" className="alert-input"></span>
                                    </div>
                                    <div className="content-input">
                                        <input onChange={fomrPage1} placeholder={verif === "FR" ? "Email" : "Email"} type="email" name="email" id="email" />
                                        <span id="alert-input-mail" className="alert-input"></span>
                                    </div>
                                </div>
                                <a className="btn-next" id="btn-next-one">
                                    {verif === "FR" ? "Suivant" : "Next"}
                                </a>
                            </div>
                            {/* deuxieme page */}
                            <div id="form-info-two" className="form-info">
                                <div className="group-card-choice">
                                    <div className="card-choice-booking">
                                        <input type="radio" className="card-choice" name="choice-booking" onChange={activeBtnPage2} value="full" />
                                        <label htmlFor="kraken"> {verif === "FR" ? "De 2 à 14 personnes" : "From 2 to 14 people"}</label>
                                        <p className="textSubtitle">{verif === "FR" ? "Réservation complète du gîte" : "Complete reservation of the cottage"}</p>
                                        <p className="textSubtitle"><FontAwesomeIcon className='star_ico-btn' icon={faTriangleExclamation} />
                                            {verif === "FR"
                                                ? " Basse saison / moyenne saison / haute saison"
                                                : " Low season / mid season / high season"}
                                        </p>
                                    </div>
                                    <div className="card-choice-booking">
                                        <input type="radio" className="card-choice" name="choice-booking" onChange={activeBtnPage2} value="partiel" />
                                        <label htmlFor="kraken"> {verif === "FR" ? "Pour 2 personnes" : "For 2 people"}</label>
                                        <p className="textSubtitle">{verif === "FR" ? "Réservation partielle du gîte" : "Partial reservation of the cottage"}</p>
                                        <p className="textSubtitle"><FontAwesomeIcon className='star_ico-btn' icon={faTriangleExclamation} />
                                            {verif === "FR"
                                                ? " Hors jours fériés et hors saison"
                                                : " Outside public holidays and off-season"}
                                        </p>
                                    </div>
                                </div>
                                <a className="btn-next" id="btn-next-two">
                                    {verif === "FR" ? "Suivant" : "Next"}
                                </a>
                            </div>
                            {/* troisieme page */}
                            <div id="form-info-three" className="form-info">
                                <div className="group-card-tarif">
                                    <div className="card-nbpeople">
                                        <p className="fornbpeople">
                                            {verif === "FR" ? "Nombre de personnes en tout :" : "Total number of people:"}{" "}
                                            <span className="value-nb-people">7</span>
                                        </p>
                                        <input type="range" name="range" min="2" max="14" id="nbpeople" className="inputRange" onChange={changeNbPeople} />
                                    </div>
                                </div>
                                <div className="group-card-tarif">
                                    <div className="card-nbpeople">
                                        <p className="fornbpeople">{verif === "FR" ? "Nombre d'enfants :" : "Number of children:"}{" "}
                                            <span className="value-nb-child">5</span>
                                        </p>
                                        <input type="range" name="range" min="0" max="12" id="nbchild" className="inputRange" onChange={changeNbChild} />
                                    </div>
                                </div>
                                <a className="btn-next BTNREADY" href="#form-info-four">
                                    {verif === "FR" ? "Suivant" : "Next"}
                                </a>
                            </div>
                            {/* quatrieme page */}
                            <div id="form-info-four" className="form-info">
                                <div className="group-card-date">
                                    <p>
                                        {verif === "FR" ? "Sélectionnez vos dates de réservation" : "Select your booking dates"}
                                    </p>
                                    <div className="card-date">
                                        <span>
                                            {verif === "FR" ? "Date d'arrivée" : "Arrival Date"}
                                        </span>
                                        <input type="date" name="date" min={getDatePlus7D} max="2026" id="dateStart" className="inputDate" onChange={minDateEnd} />
                                    </div>
                                    <div className="card-date">
                                        <span> {verif === "FR" ? "Date de sortie" : "Departure Date"}
                                        </span>
                                        <input type="date" name="date" id="dateEnd" className="inputDate" onChange={formPage4} />
                                    </div>
                                </div>
                                <a className="btn-next" id="btn-next-four" href="#form-info-four">
                                    {verif === "FR" ? "Suivant" : "Next"}
                                </a>
                            </div>
                            {/* cinquieme page */}
                            <div id="form-info-five" className="form-info">
                                <div className="group-card-num-nationality">
                                    <p>
                                        {verif === "FR" ? "Finalisation de la demande" : "Finalizing the request"}
                                    </p>
                                    <select id='nationality' className='inputvalid' defaultValue='french' name="nationality" >
                                        <option value=""> Nationalité </option>
                                        <option value="afghan">Afghan</option>
                                        <option value="albanian">Albanian</option>
                                        <option value="algerian">Algerian</option>
                                        <option value="american">American</option>
                                        <option value="andorran">Andorran</option>
                                        <option value="angolan">Angolan</option>
                                        <option value="antiguans">Antiguans</option>
                                        <option value="argentinean">Argentinean</option>
                                        <option value="armenian">Armenian</option>
                                        <option value="australian">Australian</option>
                                        <option value="austrian">Austrian</option>
                                        <option value="azerbaijani">Azerbaijani</option>
                                        <option value="bahamian">Bahamian</option>
                                        <option value="bahraini">Bahraini</option>
                                        <option value="bangladeshi">Bangladeshi</option>
                                        <option value="barbadian">Barbadian</option>
                                        <option value="barbudans">Barbudans</option>
                                        <option value="batswana">Batswana</option>
                                        <option value="belarusian">Belarusian</option>
                                        <option value="belgian">Belgian</option>
                                        <option value="belizean">Belizean</option>
                                        <option value="beninese">Beninese</option>
                                        <option value="bhutanese">Bhutanese</option>
                                        <option value="bolivian">Bolivian</option>
                                        <option value="bosnian">Bosnian</option>
                                        <option value="brazilian">Brazilian</option>
                                        <option value="british">British</option>
                                        <option value="bruneian">Bruneian</option>
                                        <option value="bulgarian">Bulgarian</option>
                                        <option value="burkinabe">Burkinabe</option>
                                        <option value="burmese">Burmese</option>
                                        <option value="burundian">Burundian</option>
                                        <option value="cambodian">Cambodian</option>
                                        <option value="cameroonian">Cameroonian</option>
                                        <option value="canadian">Canadian</option>
                                        <option value="cape verdean">Cape Verdean</option>
                                        <option value="central african">Central African</option>
                                        <option value="chadian">Chadian</option>
                                        <option value="chilean">Chilean</option>
                                        <option value="chinese">Chinese</option>
                                        <option value="colombian">Colombian</option>
                                        <option value="comoran">Comoran</option>
                                        <option value="congolese">Congolese</option>
                                        <option value="costa rican">Costa Rican</option>
                                        <option value="croatian">Croatian</option>
                                        <option value="cuban">Cuban</option>
                                        <option value="cypriot">Cypriot</option>
                                        <option value="czech">Czech</option>
                                        <option value="danish">Danish</option>
                                        <option value="djibouti">Djibouti</option>
                                        <option value="dominican">Dominican</option>
                                        <option value="dutch">Dutch</option>
                                        <option value="east timorese">East Timorese</option>
                                        <option value="ecuadorean">Ecuadorean</option>
                                        <option value="egyptian">Egyptian</option>
                                        <option value="emirian">Emirian</option>
                                        <option value="equatorial guinean">Equatorial Guinean</option>
                                        <option value="eritrean">Eritrean</option>
                                        <option value="estonian">Estonian</option>
                                        <option value="ethiopian">Ethiopian</option>
                                        <option value="fijian">Fijian</option>
                                        <option value="filipino">Filipino</option>
                                        <option value="finnish">Finnish</option>
                                        <option value="french">French</option>
                                        <option value="gabonese">Gabonese</option>
                                        <option value="gambian">Gambian</option>
                                        <option value="georgian">Georgian</option>
                                        <option value="german">German</option>
                                        <option value="ghanaian">Ghanaian</option>
                                        <option value="greek">Greek</option>
                                        <option value="grenadian">Grenadian</option>
                                        <option value="guatemalan">Guatemalan</option>
                                        <option value="guinea-bissauan">Guinea-Bissauan</option>
                                        <option value="guinean">Guinean</option>
                                        <option value="guyanese">Guyanese</option>
                                        <option value="haitian">Haitian</option>
                                        <option value="herzegovinian">Herzegovinian</option>
                                        <option value="honduran">Honduran</option>
                                        <option value="hungarian">Hungarian</option>
                                        <option value="icelander">Icelander</option>
                                        <option value="indian">Indian</option>
                                        <option value="indonesian">Indonesian</option>
                                        <option value="iranian">Iranian</option>
                                        <option value="iraqi">Iraqi</option>
                                        <option value="irish">Irish</option>
                                        <option value="israeli">Israeli</option>
                                        <option value="italian">Italian</option>
                                        <option value="ivorian">Ivorian</option>
                                        <option value="jamaican">Jamaican</option>
                                        <option value="japanese">Japanese</option>
                                        <option value="jordanian">Jordanian</option>
                                        <option value="kazakhstani">Kazakhstani</option>
                                        <option value="kenyan">Kenyan</option>
                                        <option value="kittian and nevisian">Kittian and Nevisian</option>
                                        <option value="kuwaiti">Kuwaiti</option>
                                        <option value="kyrgyz">Kyrgyz</option>
                                        <option value="laotian">Laotian</option>
                                        <option value="latvian">Latvian</option>
                                        <option value="lebanese">Lebanese</option>
                                        <option value="liberian">Liberian</option>
                                        <option value="libyan">Libyan</option>
                                        <option value="liechtensteiner">Liechtensteiner</option>
                                        <option value="lithuanian">Lithuanian</option>
                                        <option value="luxembourger">Luxembourger</option>
                                        <option value="macedonian">Macedonian</option>
                                        <option value="malagasy">Malagasy</option>
                                        <option value="malawian">Malawian</option>
                                        <option value="malaysian">Malaysian</option>
                                        <option value="maldivan">Maldivan</option>
                                        <option value="malian">Malian</option>
                                        <option value="maltese">Maltese</option>
                                        <option value="marshallese">Marshallese</option>
                                        <option value="mauritanian">Mauritanian</option>
                                        <option value="mauritian">Mauritian</option>
                                        <option value="mexican">Mexican</option>
                                        <option value="micronesian">Micronesian</option>
                                        <option value="moldovan">Moldovan</option>
                                        <option value="monacan">Monacan</option>
                                        <option value="mongolian">Mongolian</option>
                                        <option value="moroccan">Moroccan</option>
                                        <option value="mosotho">Mosotho</option>
                                        <option value="motswana">Motswana</option>
                                        <option value="mozambican">Mozambican</option>
                                        <option value="namibian">Namibian</option>
                                        <option value="nauruan">Nauruan</option>
                                        <option value="nepalese">Nepalese</option>
                                        <option value="new zealander">New Zealander</option>
                                        <option value="ni-vanuatu">Ni-Vanuatu</option>
                                        <option value="nicaraguan">Nicaraguan</option>
                                        <option value="nigerien">Nigerien</option>
                                        <option value="north korean">North Korean</option>
                                        <option value="northern irish">Northern Irish</option>
                                        <option value="norwegian">Norwegian</option>
                                        <option value="omani">Omani</option>
                                        <option value="pakistani">Pakistani</option>
                                        <option value="palauan">Palauan</option>
                                        <option value="panamanian">Panamanian</option>
                                        <option value="papua new guinean">Papua New Guinean</option>
                                        <option value="paraguayan">Paraguayan</option>
                                        <option value="peruvian">Peruvian</option>
                                        <option value="polish">Polish</option>
                                        <option value="portuguese">Portuguese</option>
                                        <option value="qatari">Qatari</option>
                                        <option value="romanian">Romanian</option>
                                        <option value="russian">Russian</option>
                                        <option value="rwandan">Rwandan</option>
                                        <option value="saint lucian">Saint Lucian</option>
                                        <option value="salvadoran">Salvadoran</option>
                                        <option value="samoan">Samoan</option>
                                        <option value="san marinese">San Marinese</option>
                                        <option value="sao tomean">Sao Tomean</option>
                                        <option value="saudi">Saudi</option>
                                        <option value="scottish">Scottish</option>
                                        <option value="senegalese">Senegalese</option>
                                        <option value="serbian">Serbian</option>
                                        <option value="seychellois">Seychellois</option>
                                        <option value="sierra leonean">Sierra Leonean</option>
                                        <option value="singaporean">Singaporean</option>
                                        <option value="slovakian">Slovakian</option>
                                        <option value="slovenian">Slovenian</option>
                                        <option value="solomon islander">Solomon Islander</option>
                                        <option value="somali">Somali</option>
                                        <option value="south african">South African</option>
                                        <option value="south korean">South Korean</option>
                                        <option value="spanish">Spanish</option>
                                        <option value="sri lankan">Sri Lankan</option>
                                        <option value="sudanese">Sudanese</option>
                                        <option value="surinamer">Surinamer</option>
                                        <option value="swazi">Swazi</option>
                                        <option value="swedish">Swedish</option>
                                        <option value="swiss">Swiss</option>
                                        <option value="syrian">Syrian</option>
                                        <option value="taiwanese">Taiwanese</option>
                                        <option value="tajik">Tajik</option>
                                        <option value="tanzanian">Tanzanian</option>
                                        <option value="thai">Thai</option>
                                        <option value="togolese">Togolese</option>
                                        <option value="tongan">Tongan</option>
                                        <option value="trinidadian or tobagonian">Trinidadian or Tobagonian</option>
                                        <option value="tunisian">Tunisian</option>
                                        <option value="turkish">Turkish</option>
                                        <option value="tuvaluan">Tuvaluan</option>
                                        <option value="ugandan">Ugandan</option>
                                        <option value="ukrainian">Ukrainian</option>
                                        <option value="uruguayan">Uruguayan</option>
                                        <option value="uzbekistani">Uzbekistani</option>
                                        <option value="venezuelan">Venezuelan</option>
                                        <option value="vietnamese">Vietnamese</option>
                                        <option value="welsh">Welsh</option>
                                        <option value="yemenite">Yemenite</option>
                                        <option value="zambian">Zambian</option>
                                        <option value="zimbabwean">Zimbabwean</option>
                                    </select>

                                    <input type="tel" id="phone" name="phone" placeholder={verif === "FR" ? "Numéro de téléphone" : "Phone number"} className="inputnovalid" onChange={formPage5} />

                                    <input placeholder={verif === "FR" ? "Adresse" : "Address"} type="text" id="street-address" name="street-address" className="inputnovalid" onChange={formPage5} />

                                    <input type="tel" placeholder={verif === "FR" ? "Code postal" : "Postal code"} id="postal-code" name="postal-code" className="inputnovalid" onChange={formPage5} />

                                    <input placeholder={verif === "FR" ? "Ville" : "City"} type="text" id="city" name="city" className="inputnovalid" onChange={formPage5} />
                                </div>
                                <a className="btn-next" id="btn-next-five" href="#form-info-six">
                                    {verif === "FR" ? "Suivant" : "Next"}
                                </a>
                            </div>
                            {/* cinquieme page */}
                            <div id="form-info-six" className="form-info">
                                <div className="group-card-num-nationality">
                                    <p>
                                        {verif === "FR" ? "Finalisation de la demande" : "Finalizing the request"}
                                    </p>
                                    <textarea placeholder={verif === "FR" ? "Joindre un message (facultatif)" : "Attach a message (optional)"} id="message-form" className="classFormMessage"></textarea>
                                </div>
                                <a className="btn-next BTNREADY" onClick={sendForm}>
                                    {verif === "FR" ? "Envoyer la demande" : "Send the request"}
                                </a>
                            </div>
                            {/* page autre */}
                            <div id="form-info-autre" className="form-info">
                                <p>
                                    {verif === "FR" ? "Sélectionnez une option de location" : "Select a rental option"}
                                </p>
                                <select id="option-booking">
                                    <option>
                                        {verif === "FR" ? "Chambre PMR" : "Accessible Room"}
                                    </option>
                                    <option>
                                        {verif === "FR" ? "Chambre PMR + cuisine" : "Accessible Room + Kitchen"}
                                    </option>
                                    <option>
                                        {verif === "FR" ? "Chambre PMR + cuisine + salon" : "Accessible Room + Kitchen + Living Room"}
                                    </option>
                                    <option>
                                        {verif === "FR" ? "Chambre PMR + cuisine + salon + sauna" : "Accessible Room + Kitchen + Living Room + Sauna"}
                                    </option>
                                </select>
                                <a className="btn-next BTNREADY" href="#form-info-four">
                                    {verif === "FR" ? "Suivant" : "Next"}
                                </a>
                            </div>
                            {/* page envoi Valide*/}
                            <div id="form-info-valid" className="infovalid">
                                <h3 className="entete-valid-booking">
                                    {verif === "FR" ? "Félicitations !!!" : "Congratulations !!!"}
                                </h3>
                                <div className="content-fixe-valid">
                                    <div className="circle-anime valid-circle">
                                        <FontAwesomeIcon icon={faFaceSmileBeam} className="ico-valid" />
                                    </div>
                                </div>
                                <p id="info-finish-valid">
                                    {verif === "FR" ? "Une réponse vous sera donnée rapidement..." : "You will receive a response shortly..."}
                                </p>
                                <a className="btn-next BTNREADY" id="btn-next-valid" onClick={resetInfo}>
                                    {verif === "FR" ? "Actualiser" : "Refresh"}
                                </a>
                            </div>
                            {/* page envoi non Valide*/}
                            <div id="form-info-novalid" className="infovalid">
                                <h3 className="entete-no-valid-booking">
                                    {verif === "FR" ? "Une erreur s'est produite..." : "An error has occurred..."}
                                </h3>
                                <div className="content-fixe-valid">
                                    <div className="circle-anime novalid-circle">
                                        <FontAwesomeIcon icon={faFaceRollingEyes} className="ico-valid" />
                                    </div>
                                </div>
                                <p id="info-finish-valid">
                                    {verif === "FR" ? "Veuillez réessayer..." : "Please try again..."}
                                </p>
                                <a className="btn-next BTNREADY" id="btn-next-valid" onClick={resetInfo}>
                                    {verif === "FR" ? "Actualiser" : "Refresh"}
                                </a>
                            </div>
                        </form>
                    </div>
                </div>
            </section>
        </>
    )
}

export default formBooking;