// incorporation des pages
// import logo from './logo.ico';
// import React from "react";
import './App.css';
import Header from "./composants/menu/Header";
import Main from "./composants/main/Intro";
import GiteEtChambre from "./composants/gite/GiteEtChambre";
import AllPrice from "./composants/price/allPrice";
import AvisGoogle from "./composants/avis/AvisGoogle";
import FormBooking from "./composants/booking/Form";
import 'react-responsive-carousel/lib/styles/carousel.min.css';

function App() {
  
  const verifExistLangParameter = localStorage.getItem("LangParameter");
  if (!verifExistLangParameter) {
    
    localStorage.setItem("LangParameter", "FR");
  }
  const resizeObserverLoopErr = () => {
    let resizeObserverLoopErrHandler = (e) => {
      if (e.message === "ResizeObserver loop completed with undelivered notifications.") {
        e.stopImmediatePropagation();
      }
    };
    window.addEventListener("error", resizeObserverLoopErrHandler);
  };
  
  resizeObserverLoopErr();
  
  return (
    <>
        <Header />
      <main className='main-of'>
        <Main />
        <GiteEtChambre />
        <AvisGoogle />
        <AllPrice />
        <FormBooking />
      </main>
    </>
  )
}

export default App;

