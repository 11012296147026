import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark, faEye, faDownload, faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import imageLittleGroupe from '../booking/img/little-groupe.svg'
import imageGroupe from '../booking/img/group-people.svg'
import { EmbedPDF } from "@simplepdf/react-embed-pdf";
import gt1 from "./docs/gt1.pdf";
import gt1p2 from "./docs/gt1p2.pdf";
import gt1EN from "./docs/gt1EN.pdf";
import gt1p2EN from "./docs/gt1p2EN.pdf";
function allPrice() {
    function displayModalTarifGroup(e) {
        e.preventDefault()
        let modalPage = document.getElementById('modalTarifGroup')
        if (modalPage) {
            modalPage.style.display = "flex"
        }
    }
    function closeModalTarif(e) {
        e.preventDefault()
        let modalPage = document.getElementById('modalTarifGroup')
        if (modalPage) {
            modalPage.style.display = "none"
        }
    }
    function displayModalTarif2p(e) {
        e.preventDefault()
        let modalPage = document.getElementById('modal-tarif-2')
        if (modalPage) {
            modalPage.style.display = "flex"
        }
    }
    function closeModalTarif2p(e) {
        e.preventDefault()
        let modalPage = document.getElementById('modal-tarif-2')
        if (modalPage) {
            modalPage.style.display = "none"
        }
    }
    const verif = localStorage.getItem("LangParameter");
    return (
        <>
            {/* page principal */}
            <section id="Price" className="tarif-gite">
                <div className="group-tarif">
                    <div className="headTarif">
                        <h2 className="subtitle">{verif === "FR" ? "Tarifs" : "Tariff"}</h2>
                        <p className="textSubtitle">
                            {verif === "FR"
                                ? "De nombreuses options de réservation sont mises en place pour vous accueillir selon vos possibilités."
                                : "Many booking options are in place to accommodate you according to your possibilities."}
                        </p>
                        <div className="groupeinfo">
                            <img className='img-group-people' src={imageGroupe}></img>
                            <p>
                                {verif === "FR"
                                    ? "Tarification par nuit jusqu’à 14 personnes."
                                    : "Pricing per night for up to 14 people."}
                            </p>
                            <a onClick={displayModalTarifGroup} className="button-grille" href="#"><FontAwesomeIcon className='star_ico-btn' icon={faEye} /> <span className="btn_info"> {verif === "FR" ? "Visualiser la grille tarifaire" : "View the price list"}</span>
                            </a>
                            <a className="button-grille" href={verif === "FR" ? gt1 : gt1EN} download={verif === "FR" ? "Tarif-Groupe.pdf" : "Tarif-GroupeEN.pdf"}><FontAwesomeIcon className='star_ico-btn' icon={faDownload} /><span className="btn_info">{verif === "FR" ? "Télécharger le PDF" : "Download PDF"}</span>
                            </a>
                        </div>
                        <div className="groupeinfo">
                            <img className='img-group-people' src={imageLittleGroupe}></img>
                            <p>
                                {verif === "FR" ? "Tarification par nuit pour 2 personnes." : "Pricing per night for 2 people."}
                            </p>
                            <p className="textSubtitle"><FontAwesomeIcon className='star_ico-btn' icon={faTriangleExclamation} /> 
                                {verif === "FR"
                                    ? "Seulement hors jours fériés et hors saison *"
                                    : "Only outside public holidays and off-season *"}
                            </p>
                            <a onClick={displayModalTarif2p} className="button-grille" href="#"><FontAwesomeIcon className='star_ico-btn' icon={faEye} /> <span className="btn_info"> {verif === "FR" ? "Visualiser la grille tarifaire" : "View the price list"}</span>
                            </a>
                            <a className="button-grille" href={verif === "FR" ? gt1p2 : gt1p2EN} download={verif === "FR" ? "Tarif-2p.pdf" : "Tarif-2pEN.pdf"}><FontAwesomeIcon className='star_ico-btn' icon={faDownload} /><span className="btn_info">{verif === "FR" ? "Télécharger le PDF" : "Download PDF"}</span>
                            </a>
                        </div>
                    </div>
                </div>
            </section>
            {/* PAGE TARIF GROUPE 14 */}
            <section id="modalTarifGroup">
                <div className="card-modal-tarifs">
                    <FontAwesomeIcon onClick={closeModalTarif} className='star_ico-close-modal' icon={faXmark} />
                    <div className="headTarif">
                        <h2 className="subtitle">
                            {verif === "FR" ? "Tarifs 2025" : "Rates 2025"}
                        </h2>
                        <p className="textSubtitle">
                            {verif === "FR"
                                ? "Tarification par nuit jusqu’à 14 personnes *"
                                : "Pricing per night for up to 14 people *"}
                        </p>
                    </div>
                    <div className="table-Tarif">
                        <table>
                            <thead className="table-oneLine">
                                <tr className="table-one">
                                    <th>{verif === "FR" ? "Saisons" : "Seasons"}</th>
                                    <th>{verif === "FR" ? "Du Lundi au Vendredi" : "Monday to Friday"}</th>
                                    <th>{verif === "FR" ? "Du Samedi au Dimanche" : "Saturday to Sunday"}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="table-one tableColumn">
                                        {verif === "FR"
                                            ? "Haute saison"
                                            : "High season"}{" "}
                                        <span>
                                            {verif === "FR"
                                                ? "Du 1er Juillet au 31 Août"
                                                : "From July 1st to August 31st"}
                                        </span>
                                    </td>
                                    <td className="table-price">
                                        {verif === "FR" ? "470€/ Nuit" : "€470/ Night"}
                                    </td>
                                    <td className="table-price">
                                        {verif === "FR" ? "535€/ Nuit" : "€535/ Night"}
                                    </td>
                                </tr>
                            </tbody>
                            <tbody>
                                <tr>
                                    <td className="table-one tableColumn">
                                        {verif === "FR"
                                            ? "Moyenne saison"
                                            : "Mid-season"}{" "}
                                        <span>
                                            {verif === "FR"
                                                ? "Du 1er Septembre au 31 Octobre"
                                                : "From September 1st to October 31st"}
                                        </span>
                                    </td>
                                    <td className="table-price">
                                        {verif === "FR" ? "400€/ Nuit" : "€400/ Night"}
                                    </td>
                                    <td className="table-price">
                                        {verif === "FR" ? "450€/ Nuit" : "€450/ Night"}
                                    </td>
                                </tr>
                            </tbody>
                            <tbody>
                                <tr>
                                    <td className="table-one tableColumn">
                                        {verif === "FR"
                                            ? "Basse saison"
                                            : "Low season"}{" "}
                                        <span>
                                            {verif === "FR"
                                                ? "Du 1er Novembre au 28 Février"
                                                : "From November 1st to February 28th"}
                                        </span>
                                    </td>
                                    <td className="table-price">
                                        {verif === "FR" ? "330€/ Nuit" : "€330/ Night"}
                                    </td>
                                    <td className="table-price">
                                        {verif === "FR" ? "390€/ Nuit" : "€390/ Night"}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <p className="info_table-more">
                        {verif === "FR"
                            ? "Moyenne saison : + Vacances scolaires et jours fériés *"
                            : "Mid-season: + School holidays and public holidays *"}
                    </p>
                    <div className='igcontent'>
                        <div className="displayInfo">
                            <h3 className="display-info-tarif">
                                {verif === "FR" ? "À savoir :" : "Please Note:"}
                            </h3>
                            <ul>
                                <li className="liGite">
                                    {verif === "FR"
                                        ? "Les prix s'entendent pour la location du gîte entier"
                                        : "The prices are for the rental of the entire cottage"}
                                </li>
                                <li className="liGite">
                                    {verif === "FR"
                                        ? "Un acompte de 30% sera demandé lors de la réservation"
                                        : "A 30% deposit will be required at the time of booking"}
                                </li>
                                <li className="liGite">
                                    {verif === "FR"
                                        ? "Un dépôt de garantie de 1800€ vous sera demandé à l'arrivée, et restitué lors de l’état des lieux de sortie."
                                        : "A security deposit of €1800 will be required upon arrival and returned after the final inspection."}
                                </li>
                                <li className="liGite">
                                    {verif === "FR"
                                        ? "Taxes de séjour non incluses : 2.75 € par personne et par nuit."
                                        : "Tourist taxes not included: €2.75 per person per night."}
                                </li>
                                <li className="liGite">
                                    {verif === "FR"
                                        ? "Les animaux ne sont pas autorisés"
                                        : "Pets are not allowed"}
                                </li>
                            </ul>
                            <h3 className="display-info-tarif">
                                {verif === "FR" ? "Services inclus dans le tarif :" : "Services included in the rate:"}
                            </h3>
                            <ul>
                                <li className="liGite">
                                    {verif === "FR"
                                        ? "Réception ouverte de 9h à 18h"
                                        : "Reception open from 9 AM to 6 PM"}
                                </li>
                                <li className="liGite">{verif === "FR" ? "Sauna" : "Sauna"}</li>
                                <li className="liGite">{verif === "FR" ? "WiFi gratuit" : "Free WiFi"}</li>
                                <li className="liGite">
                                    {verif === "FR"
                                        ? "Linge de maison fourni (draps, serviettes)"
                                        : "Bed linen provided (sheets, towels)"}
                                </li>
                                <li className="liGite">
                                    {verif === "FR"
                                        ? "Quatre chambres et un dortoir de quatre couchages"
                                        : "Four bedrooms and a dormitory with four beds"}
                                </li>
                                <li className="liGite">
                                    {verif === "FR"
                                        ? "Barbecue et équipements de plein air (pétanque, molki, hamac...)"
                                        : "Barbecue and outdoor equipment (petanque, molki, hammock...)"}
                                </li>
                                <li className="liGite">
                                    {verif === "FR"
                                        ? "Jeux et jouets pour enfants"
                                        : "Games and toys for children"}
                                </li>
                                <li className="liGite">
                                    {verif === "FR" ? "Produits ménagers" : "Cleaning products"}
                                </li>
                                <li className="liGite">
                                    {verif === "FR" ? "Ménage de fin de séjour" : "End-of-stay cleaning"}
                                </li>
                                <li className="liGite">
                                    {verif === "FR"
                                        ? "Lit bébé (0-2 ans) disponible gratuitement"
                                        : "Baby cot (0-2 years) available for free"}
                                </li>
                            </ul>
                            <h3 className="display-info-tarif">
                                {verif === "FR" ? "Services optionnels :" : "Optional services:"}
                            </h3>
                            <ul className="placeBottom">
                                <li className="liGite">
                                    {verif === "FR"
                                        ? "Relaxation et massage bien-être peuvent être envisagés"
                                        : "Relaxation and wellness massages available"}
                                </li>
                                <li className="liGite">
                                    {verif === "FR"
                                        ? "Ménage supplémentaire si besoin"
                                        : "Extra cleaning"}
                                </li>
                                <li className="liGite">
                                    {verif === "FR"
                                        ? "Panier de courtoisie"
                                        : "Courtesy basket"}
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
            {/* PAGE TARIF GROUPE 2 */}
            <section id="modal-tarif-2">
                <div className="card-modal-tarifs">
                    <FontAwesomeIcon onClick={closeModalTarif2p} className='star_ico-close-modal' icon={faXmark} />
                    <div className="headTarif">
                        <h2 className="subtitle">
                            {verif === "FR" ? "Tarifs 2025" : "Rates 2025"}
                        </h2>
                        <p className="textSubtitle">
                            {verif === "FR"
                                ? "Tarification par nuit pour 2 personnes *"
                                : "Pricing per night for 2 people *"}
                        </p>
                        <p className="textSubtitle">
                            {verif === "FR"
                                ? "Tarif disponible : seulement hors jours fériés et hors saison *"
                                : "Rate available: only outside public holidays and off-season *"}
                        </p>
                    </div>
                    <div className="table-Tarif">
                        <table id="table-tarif-2p">
                            <thead className="table-oneLine">
                                <tr className="table-one">
                                    <th colSpan="2">
                                        {verif === "FR" ? "Tarifs" : "Rates"}
                                    </th>
                                    <th rowSpan="2">
                                        {verif === "FR" ? "Détails de location" : "Rental Details"}
                                    </th>
                                </tr>
                                <tr className="table-one">
                                    <th>{verif === "FR" ? "Semaine" : "Week"}</th>
                                    <th>{verif === "FR" ? "Nuit" : "Night"}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="table-price">1 050 €</td>
                                    <td className="table-price">150 €</td>
                                    <td className="table-price-designation">
                                        {verif === "FR"
                                            ? "Accès : Chambre PMR"
                                            : "Access: Room for persons with reduced mobility"}
                                    </td>
                                </tr>
                                <tr>
                                    <td className="table-price">1 400 €</td>
                                    <td className="table-price">200 €</td>
                                    <td className="table-price-designation">
                                        {verif === "FR"
                                            ? "Accès : Chambre PMR + cuisine"
                                            : "Access: Room for persons with reduced mobility + kitchen"}
                                    </td>
                                </tr>
                                <tr>
                                    <td className="table-price">1 750 €</td>
                                    <td className="table-price">250 €</td>
                                    <td className="table-price-designation">
                                        {verif === "FR"
                                            ? "Accès : Chambre PMR + cuisine + salon"
                                            : "Access: Room for persons with reduced mobility"}
                                    </td>
                                </tr>
                                <tr>
                                    <td className="table-price">2 100 €</td>
                                    <td className="table-price">300 €</td>
                                    <td className="table-price-designation">
                                        {verif === "FR"
                                            ? "Accès : Chambre PMR + cuisine + salon + sauna"
                                            : "Access: Room for persons with reduced mobility + sauna"}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <p className="info_table-more">
                        {verif === "FR"
                            ? "Tarifs applicables seulement hors saisons et hors jours fériés *"
                            : "Rates only applicable outside of season and public holidays *"}
                    </p>
                    <div className="igcontent">
                        <div className="displayInfo">
                            <h3 className="display-info-tarif">
                                {verif === "FR" ? "À savoir :" : "Please Note:"}
                            </h3>
                            <ul>
                                <li className="liGite">
                                    {verif === "FR"
                                        ? "Les prix s'entendent pour une location partielle du gîte"
                                        : "Prices apply for a partial rental of the cottage"}
                                </li>
                                <li className="liGite">
                                    {verif === "FR"
                                        ? "Un acompte de 30% sera demandé lors de la réservation"
                                        : "A 30% deposit will be required at the time of booking"}
                                </li>
                                <li className="liGite">
                                    {verif === "FR"
                                        ? "Un dépôt de garantie de 300,00 € vous sera demandé à l'arrivée, et restitué lors de l’état des lieux de sortie."
                                        : "A security deposit of 300,00 € will be required upon arrival and returned after the final inspection."}
                                </li>
                                <li className="liGite">
                                    {verif === "FR"
                                        ? "Taxes de séjour non incluses : 2.75 € par personne et par nuit."
                                        : "Tourist taxes not included: €2.75 per person per night."}
                                </li>
                                <li className="liGite">
                                    {verif === "FR"
                                        ? "Les animaux ne sont pas autorisés"
                                        : "Pets are not allowed"}
                                </li>
                            </ul>
                            <h3 className="display-info-tarif">
                                {verif === "FR" ? "Services inclus dans le tarif :" : "Services included in the rate:"}
                            </h3>
                            <ul>
                                <li className="liGite">
                                    {verif === "FR"
                                        ? "Réception ouverte de 9h à 18h"
                                        : "Reception open from 9 AM to 6 PM"}
                                </li>
                                <li className="liGite">{verif === "FR" ? "WiFi gratuit" : "Free WiFi"}</li>
                                <li className="liGite">
                                    {verif === "FR"
                                        ? "Linge de maison fourni (draps, serviettes)"
                                        : "Bed linen provided (sheets, towels)"}
                                </li>
                                <li className="liGite">
                                    {verif === "FR"
                                        ? "Barbecue et équipements de plein air (pétanque, molki, hamac...)"
                                        : "Barbecue and outdoor equipment (petanque, molki, hammock...)"}
                                </li>
                                <li className="liGite">
                                    {verif === "FR" ? "Ménage de fin de séjour" : "End-of-stay cleaning"}
                                </li>
                            </ul>
                            <h3 className="display-info-tarif">
                                {verif === "FR" ? "Services optionnels :" : "Optional services:"}
                            </h3>
                            <ul className="placeBottom">
                                <li className="liGite">
                                    {verif === "FR"
                                        ? "Relaxation et massage bien-être peuvent être envisagés"
                                        : "Relaxation and wellness massages available"}
                                </li>
                                <li className="liGite">
                                    {verif === "FR"
                                        ? "Ménage supplémentaire si besoin"
                                        : "Extra cleaning"}
                                </li>
                                <li className="liGite">
                                    {verif === "FR"
                                        ? "Panier de courtoisie"
                                        : "Courtesy basket"}
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>

        </>
    );
}

export default allPrice;